import { formatString } from "@format-string/core";
import { Box } from "@mui/material";
import { Children } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

export const CardDependentes = () => {
  const { valueContractInternal } = useContextContrato();

  if (!valueContractInternal) return null;

  const { dependentes } = valueContractInternal;

  return (
    <CardContract title="Dependentes">
      <Box>
        {Children.toArray(
          dependentes.map((item) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",

                  "& > *:not(:last-child)": { margin: "12px 20px 12px 0" },
                }}
              >
                <TitleWithValueCore
                  sxStyleTitle={{ fontWeight: "500" }}
                  title="Dependentes1:"
                  value={item.nome}
                />

                <TitleWithValueCore
                  sxStyleTitle={{ fontWeight: "500" }}
                  title="CPF:"
                  value={
                    formatString({
                      type: "cpf",
                      value: item.cpf || "",
                    }) as string
                  }
                />
                <TitleWithValueCore
                  sxStyleTitle={{ fontWeight: "500" }}
                  title="Data nasc.:"
                  value={FormatDateBR(item.data_nascimento)}
                />
              </Box>
            );
          })
        )}
      </Box>
    </CardContract>
  );
};
