import { IPDFTableHeader } from "src/Pages/components/PDF/TableHeader";
import { PDFTableHeader } from "src/Pages/components/PDF/TableHeader/PDFTableHeader";

export const HeadReportConfirmationContractPDF = () => {
  const dataHeaderPDF: IPDFTableHeader = [
    {
      style: {
        width: "15%",
        textAlign: "left",
        paddingLeft: "8px",
      },
      title: "Operadora",
    },
    {
      style: {
        width: "18%",
        textAlign: "left",
        paddingLeft: "8px",
      },
      title: "Contrato",
    },
    {
      style: {
        width: "20%",
        textAlign: "left",
      },
      title: "Titular/Razão",
    },
    {
      style: {
        width: "12%",
        textAlign: "right",
      },
      title: "Data assinatura",
    },
    {
      style: {
        width: "6%",
        textAlign: "center",
      },
      title: "Parc.",
    },
    {
      style: {
        width: "9%",
        textAlign: "right",
      },
      title: "Data recebido",
    },
    {
      style: {
        width: "11%",
        textAlign: "right",
      },
      title: "Valor mensalidade",
    },

    {
      style: {
        width: "7%",
        textAlign: "right",
      },
      title: "% Corretor",
    },

    {
      style: {
        width: "10%",
        textAlign: "right",
        paddingRight: "8px",
      },
      title: "Valor comissão",
    },
  ];

  return <PDFTableHeader data={dataHeaderPDF} />;
};
